import AuthWrapper from "../components/auth/AuthWrapper";
import LogoHeader from "../components/auth/LogoHeader";
import { MdDone } from "react-icons/md";
import SubmitButton from "../components/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordActions } from "../store/forgetPasswordSlice";

export default function SuccessProcess() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const email = useSelector(state => state.forgetPassword.email)

    if (!email) {
        navigate('/')
    }

    const handleRedirect = () => {
        dispatch(forgetPasswordActions.resetEmail())
        navigate('/login')
    }

    return (
        <AuthWrapper>
            <LogoHeader />
            <div className="flex justify-center">
                <div>
                    <div className="flex justify-center items-center gap-4 mt-4">
                        <div className="text-center text-[30px] font-semibold">Suksese</div>
                        <div className="w-8 h-8 flex items-center justify-center text-[#039855] bg-[#D1FADF] rounded-full text-lg">
                            <MdDone />
                        </div>
                    </div>
                    <div className="text-gray-500 my-2">
                        Fjalëkalimi juaj është ndryshuar me sukses!
                    </div>
                    <div className="flex justify-center">
                        <img src='success.svg' alt="img" />
                    </div>
                    <div onClick={handleRedirect}><SubmitButton value="Kthehu ne balline" /></div>
                </div>
            </div>
        </AuthWrapper>
    )
}