import { createSlice } from "@reduxjs/toolkit";

const initialForgetPasswordState = { email: '' }

const forgetPasswordSlice = createSlice({
    name: 'forgetPasswordSlice',
    initialState: initialForgetPasswordState,
    reducers: {
        addEmail(state, action) {
            state.email = action.payload
        },
        resetEmail(state, action) {
            state.email = ''
        }
    },
})

export const forgetPasswordActions = forgetPasswordSlice.actions
export default forgetPasswordSlice;