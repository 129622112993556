import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axiosBaseQuery';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/users`,
    }),
    endpoints: (build) => ({
        register: build.mutation({
            query(data) {
                return {
                    url: '/register',
                    method: 'POST',
                    data: data
                }
            }
        }),
        forgotPassword: build.mutation({
            query(data) {
                return {
                    url: '/forgot-password',
                    method: 'POST',
                    data: data
                }
            }
        }),
        verifyCode: build.mutation({
            query(data) {
                return {
                    url: '/verify-code',
                    method: 'POST',
                    data: data
                }
            }
        }),
        resetPassword: build.mutation({
            query(data) {
                return {
                    url: '/reset-password',
                    method: 'PATCH',
                    data: data
                }
            }
        })
    }),
});

export const {
    useRegisterMutation,
    useForgotPasswordMutation,
    useVerifyCodeMutation,
    useResetPasswordMutation
} = authApi;