import React, { useState } from "react";
import {
  useDeleteReceiptMutation,
  useReceiptDetailsQuery,
} from "../../store/services/receiptServices";
import DetailReceiptPopUp from "../DetailReceiptPopUp/DetailReceiptPopUp";
import { toast } from "react-toastify";
import DeletePopUp from "../DeletePopUp/DeletePopUp";

const ReceiptTable = ({ data, title = "Faturat", showDeleteIcon = true }) => {
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseModal = () => {
    setSelectedReceipt(null);
    setModalOpen(false);
    setDeleteModal(false);
  };

  const [deleteReceipt] = useDeleteReceiptMutation();

  const { data: receiptDetails, isLoading } = useReceiptDetailsQuery(
    selectedReceipt
      ? { id: selectedReceipt.receipt_id, status: selectedReceipt.status }
      : {},
    { skip: !selectedReceipt }
  );

  const handleDeleteReceipt = async (id, e) => {
    e.preventDefault();
    try {
      setIsDeleting(true);
      await deleteReceipt({ id });
      toast.success("Fatura e arkivuar u fshie me sukses!");
      setDeleteModal(false);
      setSelectedReceipt(null);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete receipt:", error);
      toast.error("Failed to delete receipt.");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="pl-4 sm:block hidden">
      <p className="text-xl font-medium pb-4">{title}</p>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg hidden sm:block">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Numri Fiskal / NUI
              </th>
              <th scope="col" className="px-6 py-3">
                Faturat
              </th>
              <th scope="col" className="px-6 py-3">
                Data e ngarkimit
              </th>
              <th scope="col" className="px-6 py-3">
                Statusit
              </th>
              {showDeleteIcon && (
                <th scope="col" className="px-6 py-3">
                  Aksionet
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((receipt) => (
                <tr
                  key={receipt.receipt_id}
                  className="cursor-pointer odd:bg-white  even:bg-gray-50 "
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {receipt.vat_number}
                  </th>
                  <td className="px-6 py-4">{receipt.business_name}</td>
                  <td className="px-6 py-4">
                    {new Date(receipt.created_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4">
                    <div
                      className={`flex gap-2 items-center border rounded-full w-max px-2 ${
                        receipt.status === "pending"
                          ? "bg-lightRed border-darkRed"
                          : receipt.status === "archived"
                          ? "bg-lighterGrey border-darkGrey"
                          : receipt.status === "rejected"
                          ? "bg-lightRed border-darkRed"
                          : "bg-lightRed border-darkRed"
                      }`}
                    >
                      <div
                        className={`rounded-full w-2 h-2 ${
                          receipt.status === "pending"
                            ? "bg-darkRed"
                            : receipt.status === "archived"
                            ? "bg-darkGrey"
                            : receipt.status === "rejected"
                            ? "bg-darkRed"
                            : "bg-darkRed"
                        }`}
                      ></div>
                      <p
                        className={`${
                          receipt.status === "pending"
                            ? "text-darkRed"
                            : receipt.status === "archived"
                            ? "text-darkGrey"
                            : receipt.status === "rejected"
                            ? "text-darkRed"
                            : "text-darkRed"
                        }`}
                      >
                        {receipt.status === "pending"
                          ? "Progres"
                          : receipt.status === "archived"
                          ? "Arkivuar"
                          : receipt.status === "rejected"
                          ? "Refuzuar"
                          : "Progres"}
                      </p>
                    </div>
                  </td>
                  {showDeleteIcon && (
                    <td className="px-6 py-4 flex items-center gap-3">
                      <img
                        src="/eye.png"
                        alt="Eye icon"
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedReceipt(receipt);
                          setModalOpen(true);
                          setDeleteModal(false);
                        }}
                      />

                      {receipt.status === "archived" && (
                        <img
                          src="/delete.png"
                          alt="Delete icon"
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedReceipt(receipt);
                            setDeleteModal(true);
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  Nuk ka fatura të gjetura.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DetailReceiptPopUp
        isOpen={modalOpen}
        onClose={handleCloseModal}
        isLoading={isLoading}
        receiptDetails={receiptDetails}
      />

      {deleteModal && (
        <DeletePopUp
          modalOpen={deleteModal}
          selectedReceipt={selectedReceipt}
          onClose={handleCloseModal}
          onDelete={(e) => handleDeleteReceipt(selectedReceipt.receipt_id, e)}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default ReceiptTable;
