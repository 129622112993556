import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { useEffect, useState } from "react";
import LogoHeader from "../components/auth/LogoHeader";
import InputField from "../components/auth/InputField";
import SubmitButton from "../components/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from 'jwt-decode';
import { loginUser } from "../store/authSlice";
import { ClipLoader } from "react-spinners";
import AuthWrapper from "../components/auth/AuthWrapper";

export default function Login() {

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    })
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const token = useSelector(state => state.auth.token)
    const [loading, setLoading] = useState(false)

    const handleCredentialsChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        dispatch(loginUser(credentials))
            .unwrap()
            .then((response) => {
                console.log("jwt", jwtDecode(response.access_token))
                localStorage.setItem('giz_access_token', response.access_token);
                localStorage.setItem('giz_refresh_token', response.refresh_token);
                navigate('/')
                setLoading(false)
            })
            .catch((error) => {
                console.error('Login failed:', error.message);
                setError("Wrong credentials");
                setLoading(false)
            });
    }

    return (
        <AuthWrapper>
            <div className="">
                <LogoHeader />
                <div className="text-center">
                    <h1 className="text-[30px] font-semibold">Kyqu në llogarinë tënde</h1>
                    <h3 className="text-gray-600">Mirë se u ktheve! Ju lutemi, shkruani të dhënat tuaja.</h3>
                </div>
                <div className="my-4 mt-8 z-20 z-30">
                    <form onSubmit={handleFormSubmit}>
                        <div className="flex flex-col gap-4">
                            <InputField type="email" name="email" label="Email" value={credentials.email} placeholder="Shkruani emailin tuaj" onChange={handleCredentialsChange} required={true} />
                            <InputField type="password" name="password" label="Password" value={credentials.password} placeholder="••••••••" onChange={handleCredentialsChange} required={true} />
                        </div>
                        {error && <div className="text-red-600 my-4">{error}</div>}
                        <div className="flex justify-between my-6">
                            <div className="flex gap-2 items-center">
                                <input type="checkbox" className="w-4 h-4 accent-primary border text-blue-600 border-gray-300 rounded-xl" />
                                <div className="text-sm">Më kujto për 30 ditë</div>
                            </div>
                            <Link to="/forgot_password"><div className="text-sm text-primary font-semibold hover:underline">Keni harruar fjalëkalimin</div></Link>
                        </div>
                        <div className="flex flex-col items-center gap-4 my-4">
                            <SubmitButton value="Kyqu" />
                            <div className="w-full bg-white text-gray-700 rounded-md py-[6px] px-4 flex gap-2 items-center justify-center border border-gray-300">
                                <FcGoogle size={26} />
                                <span className="font-medium">Hyr me Google</span>
                            </div>
                            {loading && <ClipLoader color="var(--primary-color)" />}
                        </div>
                    </form>
                </div>
                <div className="text-center mt-8">
                    <div className="text-sm text-gray-600">Nuk keni një llogari? <Link to="/register"><span className="text-primary font-semibold hover:underline">Regjistrohu</span></Link></div>
                </div>
            </div>
        </AuthWrapper>
    )
}   