import { useState } from "react";
import ButtonNew from "../components/Button/ButtonNew";
import Search from "../components/Search/Search";
import LatestScanning from "../components/Receipt/LatestScanning";
import ReceiptTable from "../components/Receipt/ReceiptTable";
import Pagination from "../components/Pagination/Pagination";
import AddReceipt from "./AddReceipt";
import SideBar from "../components/SideBar/SideBar";
import { useUserReceiptsQuery } from "../store/services/receiptServices";
import NavBar from "../components/NavBar/NavBar";
import ReceiptBorder from "../components/Receipt/ReceiptBorder";

export default function Home() {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [receiptsPerPage] = useState(10);

  const { data: receiptsData } = useUserReceiptsQuery();

  const receipts = receiptsData?.[0]?.data || [];
  const totalPages = Math.ceil(receipts.length / receiptsPerPage);

  const indexOfLastReceipt = currentPage * receiptsPerPage;
  const indexOfFirstReceipt = indexOfLastReceipt - receiptsPerPage;
  const currentReceipts = receipts.slice(
    indexOfFirstReceipt,
    indexOfLastReceipt
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const validReceipts = receipts.filter((receipt) =>
    Object.values(receipt).some((value) => value !== null)
  );

  return (
    <div>
      {showSidebar && <SideBar />}
      <div className={`p-4 ${showSidebar ? "sm:ml-64" : ""}`}>
        <NavBar />
        <div className="flex gap-3 sm:py-3 py-4">
          <ButtonNew
            text="Ngarko Faturën"
            color="bg-primary"
            textStyles="text-white"
            iconSrc="/add.png"
            iconAlt="Ngarko Faturën"
            width="w-[212px]"
            height="h-[44px]"
            onClick={() => setShowUploadPopup(true)}
          />
        </div>

        <>
          {validReceipts.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-96">
              <img
                src="/emptyReceipe.png"
                alt="No Data"
                className="w-32 h-32 sm:w-48 sm:h-48"
              />
              <p className="text-lg text-center mt-4">
                Nuk keni fatura të ruajtura per ti shfaqur.
              </p>
              <div
                className="flex gap-2 items-center cursor-pointer mt-2"
                onClick={() => setShowUploadPopup(true)}
              >
                <p className="text-primary inline-block border-b border-primary">
                  Skeno faturën
                </p>
                <img src="/clickArrow.png" alt="Click" className="w-4 h-4" />
              </div>
            </div>
          ) : (
            <>
              <Search />
              <LatestScanning data={receiptsData} />

              <ReceiptTable
                data={currentReceipts}
                title="Të gjitha faturat"
                showDeleteIcon={true}
              />

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />

              <ReceiptBorder
                data={currentReceipts}
                title="Të gjitha faturat"
                showEditIcon={true}
              />
            </>
          )}
        </>
      </div>

      {showUploadPopup && (
        <AddReceipt onClose={() => setShowUploadPopup(false)} />
      )}
    </div>
  );
}
