import { createSlice } from "@reduxjs/toolkit";

const initialSidebarState = { isOpen: false };

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState: initialSidebarState,
  reducers: {
    triggerSidebar(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const sidebarActions = sidebarSlice.actions;
export default sidebarSlice;
