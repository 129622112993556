import { useEffect, useRef, useState } from "react";
import LogoHeader from "../components/auth/LogoHeader";
import SubmitButton from "../components/SubmitButton";
import AuthWrapper from "../components/auth/AuthWrapper";
import { useSelector } from "react-redux";
import maskEmail from "../utils/maskEmail";
import { useVerifyCodeMutation } from "../store/services/authServices";
import { useNavigate } from "react-router-dom";

export default function VerifyUser() {

    const inputsRef = useRef([]);
    const [code, setCode] = useState(["", "", "", ""])
    const [resendCode, setResendCode] = useState(false)
    const [counter, setCounter] = useState(59);
    const email = useSelector(state => state.forgetPassword.email)
    const [verifyCode] = useVerifyCodeMutation()
    const navigate = useNavigate()
    const [wrongCode, setWrongCode] = useState(false)

    if (!email) {
        navigate('/')
    }

    useEffect(() => {
        console.log({ email })
    }, [email])

    useEffect(() => {
        counter === 0 && setResendCode(true)
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const handleInputChange = (e, index) => {
        const currentCode = [...code]
        if (e.target.value.length === 2) {
            return;
        }
        currentCode[index] = e.target.value
        setCode(currentCode)

        if (e.target.value && index < inputsRef.current.length - 1) {
            inputsRef.current[index + 1].focus();
        }
    }

    const handleResendCode = () => {
        if (counter > 0) {
            return;
        }
        // qoje kodin ne email edhe resetoje counterin
        setResendCode(false)
        setCounter(59)
    }

    const validateCode = () => {
        if (code.some(number => number === "")) {
            return false;
        }
        else {
            return true
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validateCode()) {
            try {
                const integer = parseInt(code.join(""))
                const response = await verifyCode({ email, code: integer }).unwrap()
                if (response) {
                    console.log(response)
                    navigate('/change_password')
                }
            }
            catch (error) {
                setWrongCode(true)
                console.log("invalid code")
            }
        }
        else {
        }
    }

    return (
        <AuthWrapper>
            <LogoHeader />
            <div className="text-center">
                <h1 className="text-[30px] font-semibold">Verifikoni kodin tuaj</h1>
                <h3 className="text-gray-600">Vendosni kodin që sapo morët në adresën tuaj të emailit që përfundon me {email && maskEmail(email)}</h3>
            </div>
            <div className="mt-8 mb-4">
                <form onSubmit={handleFormSubmit}>
                    <div className="flex justify-between gap-3">
                        {Array(4)
                            .fill(0)
                            .map((_, i) => (
                                <input
                                    key={i}
                                    type="number"
                                    className="border w-full aspect-square p-1 text-center text-[40px] font-medium rounded-lg placeholder-gray-300"
                                    placeholder="0"
                                    value={code[i]}
                                    onChange={(e) => handleInputChange(e, i)}
                                    ref={(el) => (inputsRef.current[i] = el)} // Assign ref
                                />
                            ))}
                    </div>
                    {wrongCode && <div className="text-red-600 font-bold my-2">Wrong code</div>}
                    <div className={`text-sm ${resendCode && 'cursor-pointer'} underline mt-4 mb-6 text-gray-800 font-semibold`} onClick={handleResendCode}>Ridergo kodin përsëri {counter > 0 && <span>(00:{counter})</span>}</div>
                    <SubmitButton value="Konfirmo" />
                </form>
            </div>
        </AuthWrapper>
    )
}