import React, { useState, useEffect } from "react";
import ButtonNew from "../Button/ButtonNew";
import {
  useCreateReceiptSubmittedMutation,
  useUpdateReceiptMutation,
} from "../../store/services/receiptServices";
import { useNavigate } from "react-router-dom";
import findChangedFields from "../../utils/compareObjects";

const DetailReceiptPopUp = ({ isOpen, onClose, isLoading, receiptDetails }) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [newUserData, setNewUserData] = useState({
    business_name: "",
    address: "",
    vat_number: "",
    total_amount: "",
    vat_amount: "",
    total_without_vat: "",
  });
  const [updateReceipt] = useUpdateReceiptMutation();
  const [createReceiptSubmitted] = useCreateReceiptSubmittedMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (receiptDetails) {
      setNewUserData({
        receipt_id: receiptDetails.receipt_id,
        business_name: receiptDetails.business_name || "",
        address: receiptDetails.address || "",
        vat_number: receiptDetails.vat_number || "",
        total_amount: receiptDetails.total_amount || "",
        vat_amount: receiptDetails.vat_amount || "",
        total_without_vat: receiptDetails.total_without_vat || "",
      });
    }
  }, [receiptDetails]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const changedFields = findChangedFields(receiptDetails, newUserData);

    const dataToBeSubmitted = Object.keys(changedFields).reduce((acc, key) => {
      acc[key] = changedFields[key].new;
      return acc;
    }, {});

    if (Object.keys(dataToBeSubmitted).length < 1) {
      console.log("No changes made.");
      return;
    }

    try {
      const updateResponse = await updateReceipt({
        id: newUserData.receipt_id,
        data: dataToBeSubmitted,
      }).unwrap();
      console.log("Receipt updated successfully:", updateResponse);

      const formData = new FormData();
      formData.append("receipt_id", newUserData.receipt_id);

      const submitResponse = await createReceiptSubmitted({
        formData,
        id: newUserData.receipt_id,
      }).unwrap();
      console.log("Receipt submitted successfully:", submitResponse);

      navigate("/");
      onClose();
    } catch (error) {
      console.error("Error processing receipt:", error);
    }
  };

  const handleUserDataChange = (e) => {
    setNewUserData({ ...newUserData, [e.target.name]: e.target.value });
  };

  const handleEditClick = () => setIsFormDisabled(false);

  const handleCancelEdit = () => {
    setIsFormDisabled(true);
    setNewUserData({
      receipt_id: receiptDetails.receipt_id,
      business_name: receiptDetails.business_name || "",
      address: receiptDetails.address || "",
      vat_number: receiptDetails.vat_number || "",
      total_amount: receiptDetails.total_amount || "",
      vat_amount: receiptDetails.vat_amount || "",
      total_without_vat: receiptDetails.total_without_vat || "",
    });
  };

  const fieldLabels = {
    business_name: "Emri i Biznesit",
    address: "Adresa e Biznesit",
    vat_number: "Numri fiskal / NUI",
    total_amount: "Totali",
    vat_amount: "TVSH",
    total_without_vat: "Totali pa TVSH",
  };

  return !isOpen ? null : (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-[90%] max-w-4xl max-h-[800px] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-4 right-4">
          ✕
        </button>

        <h2 className="text-lg font-semibold pb-3">Shikoni Kuponin</h2>

        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/3 flex justify-center">
            {isLoading ? (
              <p>Loading...</p>
            ) : receiptDetails ? (
              <img
                src={`${process.env.REACT_APP_BACKEND_HOST}/api/${receiptDetails.image_path}`}
                alt="Receipt"
                className="rounded-md max-w-full max-h-[500px]"
              />
            ) : (
              <p className="text-center">No image available.</p>
            )}
          </div>

          <div className="md:w-2/3">
            <form className="gap-4">
              {isLoading ? (
                <p>Loading...</p>
              ) : receiptDetails ? (
                <>
                  {Object.keys(fieldLabels).map((field) => (
                    <div key={field} className="pb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        {fieldLabels[field]}
                      </label>
                      <input
                        type="text"
                        name={field}
                        value={newUserData[field]}
                        onChange={handleUserDataChange}
                        className="mt-1 p-3 text-sm block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        disabled={isFormDisabled}
                      />
                    </div>
                  ))}

                  {isFormDisabled ? (
                    receiptDetails?.status === "pending" ? (
                      <ButtonNew
                        text="Edito"
                        color="bg-primary"
                        textStyles="text-white"
                        iconAlt="Edit"
                        width="w-full"
                        height="h-[44px]"
                        onClick={handleEditClick}
                      />
                    ) : (
                      <p className="text-gray-500">
                        Editimi nuk eshte i lejuar per kete fature!
                      </p>
                    )
                  ) : (
                    <div className="flex gap-4">
                      <ButtonNew
                        text="Anulo"
                        color="bg-darkRed"
                        textStyles="text-white"
                        iconAlt="Cancel"
                        width="w-1/2"
                        height="h-[44px]"
                        onClick={handleCancelEdit}
                      />
                      <ButtonNew
                        text="Ruaj"
                        color="bg-lightGrey"
                        textStyles="text-black"
                        width="w-1/2"
                        height="h-[44px]"
                        type="submit"
                        onClick={handleFormSubmit}
                      />
                    </div>
                  )}
                </>
              ) : (
                <p className="text-center">No receipt details found.</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailReceiptPopUp;

