import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axiosBaseQuery';

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/users`,
    }),
    endpoints: (build) => ({
        getOwnAccount: build.query({
            query() {
                return {
                    url: '/account',
                    method: 'GET',
                }
            },
            providesTags: ['Profile']
        }),
        updateOwnAccount: build.mutation({
            query({ id, data }) {
                return {
                    url: `/${id}`,
                    method: 'PUT',
                    data: data
                }
            },
            invalidatesTags: ['Profile']
        })
    }),
});

export const {
    useGetOwnAccountQuery,
    useUpdateOwnAccountMutation
} = profileApi;