export default function LogoHeader() {
  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center justify-center gap-2 text-gray-700 sm:mb-8">
        <img src="full_logo.svg" width={220} alt="img" />
      </div>
      <div className="my-6 block sm:hidden">
        <img src="atk.svg" alt="img" width={40} />
      </div>
    </div>
  );
}
