const DeletePopUp = ({
  modalOpen,
  selectedReceipt,
  onClose,
  onDelete,
  isDeleting,
}) => {
  if (!modalOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <p className="mb-4 text-gray-700">
          Jeni të sigurt që doni të fshini faturën {selectedReceipt?.receipt_id}
          ?
        </p>

        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="py-2 px-4 bg-gray-200 rounded"
            disabled={isDeleting}
          >
            Anulo
          </button>

          <button
            onClick={onDelete}
            className="py-2 px-4 bg-red-500 rounded text-white"
            disabled={isDeleting}
          >
            {isDeleting ? "Fshij..." : "Fshij"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopUp;
