import React, { useState } from "react";
import {
  useDeleteReceiptMutation,
  useReceiptDetailsQuery,
} from "../../store/services/receiptServices";
import DetailReceiptPopUp from "../DetailReceiptPopUp/DetailReceiptPopUp";
import DeletePopUp from "../DeletePopUp/DeletePopUp";
import { toast } from "react-toastify";

const ReceiptBorder = ({ data }) => {
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseModal = () => {
    setSelectedReceipt(null);
    setModalOpen(false);
    setDeleteModal(false);
  };

  const { data: receiptDetails, isLoading } = useReceiptDetailsQuery(
    selectedReceipt
      ? { id: selectedReceipt.receipt_id, status: selectedReceipt.status }
      : {},
    { skip: !selectedReceipt }
  );

  const [deleteReceipt] = useDeleteReceiptMutation();

  const handleDeleteReceipt = async (id, e) => {
    e.preventDefault();
    try {
      setIsDeleting(true);
      await deleteReceipt({ id });
      toast.success("Fatura e arkivuar u fshie me sukses!");
      setDeleteModal(false);
      setSelectedReceipt(null);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete receipt:", error);
      toast.error("Failed to delete receipt.");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="sm:hidden block">
      {Array.isArray(data) && data.length > 0 ? (
        data.map((receipt, index) => (
          <div
            key={receipt.id || index}
            className="relative flex items-center justify-between border-b py-4 px-3 sm:w-full"
          >
            <img
              alt="Fatura"
              src={`${process.env.REACT_APP_BACKEND_HOST}/api/${receipt.image_path}`}
              className="w-14 h-14 rounded-md object-cover"
            />

            <div className="flex flex-col">
              <h6 className="text-slate-800 text-[14px] font-medium">
                {receipt.business_name}
              </h6>
              <p className="text-slate-400 text-[12px]">
                {new Date(receipt.created_at).toLocaleString()}
              </p>
            </div>

            <div
              className={`rounded-full flex items-center gap-2 px-3 py-1 font-medium ${
                receipt.status === "pending"
                  ? "bg-lightRed border-darkRed"
                  : receipt.status === "archived"
                  ? "bg-lighterGrey border-darkGrey"
                  : receipt.status === "rejected"
                  ? "bg-lightRed border-darkRed"
                  : "bg-lightGreen border-darkGreen"
              }`}
            >
              <div
                className={`rounded-full w-2 h-2 ${
                  receipt.status === "pending"
                    ? "bg-darkRed"
                    : receipt.status === "archived"
                    ? "bg-darkGrey"
                    : receipt.status === "rejected"
                    ? "bg-darkRed"
                    : "bg-darkGreen"
                }`}
              ></div>
              <p
                className={`text-[12px] ${
                  receipt.status === "pending"
                    ? "text-darkRed"
                    : receipt.status === "archived"
                    ? "text-darkGrey"
                    : receipt.status === "rejected"
                    ? "text-darkRed"
                    : "text-darkGreen"
                }`}
              >
                {receipt.status === "pending"
                  ? "Progres"
                  : receipt.status === "archived"
                  ? "Arkivuar"
                  : receipt.status === "rejected"
                  ? "Refuzuar"
                  : "Aprovuar"}
              </p>
            </div>

            <div className="flex items-center gap-3">
              <img
                src="/eye.png"
                alt="Eye icon"
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedReceipt(receipt);
                  setModalOpen(true);
                  setDeleteModal(false);
                }}
              />

              {receipt.status === "archived" && (
                <img
                  src="/delete.png"
                  alt="Delete icon"
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedReceipt(receipt);
                    setDeleteModal(true);
                  }}
                />
              )}
            </div>
          </div>
        ))
      ) : (
        <div>
          <p colSpan="5" className="text-center py-4">
            Nuk ka fatura të gjetura.
          </p>
        </div>
      )}

      {deleteModal && (
        <DeletePopUp
          modalOpen={deleteModal}
          selectedReceipt={selectedReceipt}
          onClose={handleCloseModal}
          onDelete={(e) => handleDeleteReceipt(selectedReceipt.receipt_id, e)}
          isDeleting={isDeleting}
        />
      )}

      <DetailReceiptPopUp
        isOpen={modalOpen}
        onClose={handleCloseModal}
        isLoading={isLoading}
        receiptDetails={receiptDetails}
      />
    </div>
  );
};

export default ReceiptBorder;
