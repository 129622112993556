import React from "react";

const ButtonNew = ({
  text,
  onClick,
  onSubmit,
  color = "",
  textColor = "",
  weight = "",
  width = "",
  height = "",
  additionalStyles = "",
  textStyles = "",
  iconSrc = null,
  iconPosition = "left",
  iconAlt = "icon",
  type = "",
}) => {
  return (
    <button
      onClick={onClick}
      onSubmit={onSubmit}
      type={type}
      className={`flex items-center justify-center gap-2 px-4 py-2 rounded ${color} ${textColor} ${weight} ${width} ${height} hover:opacity-90 ${additionalStyles}`}
    >
      {iconSrc && iconPosition === "left" && (
        <img src={iconSrc} alt={iconAlt} className="w-5 h-5" />
      )}
      <span className={`${textStyles}`}>{text}</span>
      {iconSrc && iconPosition === "right" && (
        <img src={iconSrc} alt={iconAlt} className="w-5 h-5" />
      )}
    </button>
  );
};

export default ButtonNew;
