import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";

export const loginUser = createAsyncThunk('auth/loginUser', async (credentials) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_HOST}/api/users/log-in`, credentials);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

const initialAuthState = {
    token: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        addToken(state, action) {
            state.token = action.payload
        },
        removeToken(state, action) {
            state.token = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                state.token = action.payload;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.error.message;
            });
    },

})

export const authActions = authSlice.actions
export default authSlice;