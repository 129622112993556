import React from "react";

const removeHtmlTags = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
};


const NotificationPopUp = ({ notifications, onClose }) => (
  <div className="absolute top-24 sm:right-52 right-10 bg-white shadow-md border rounded-xl p-4 sm:w-[372px] z-50">
    <div className="flex justify-between items-center">
      <h3 className="font-semibold text-lg">Njoftimet</h3>
      <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
        &#10005;
      </button>
    </div>
    <div className="mt-2 max-h-96 overflow-y-auto">
      {notifications.slice(0, 6).map((notification, index) => (
        <div key={index} className="flex items-center gap-2 py-2">
          <img src="/atk.svg" alt="Notification" className="w-10 h-10" />
          <div>
            <p className="text-sm font-normal text-black">
              {notification.text}
            </p>
            <p className="text-xs font-normal text-black">
              {removeHtmlTags(notification.body)}
            </p>
            <p className="text-xs text-slate-400">{notification.date}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default NotificationPopUp;
