import { useState } from "react";
import InputField from "../components/auth/InputField";
import LogoHeader from "../components/auth/LogoHeader";
import SubmitButton from "../components/SubmitButton";
import AuthWrapper from "../components/auth/AuthWrapper";
import { useResetPasswordMutation } from "../store/services/authServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgetPasswordActions } from "../store/forgetPasswordSlice";

export default function ChangePassword() {

    const [passwords, setPasswords] = useState({
        new_password: '',
        confirm_password: ''
    })

    const email = useSelector(state => state.forgetPassword.email)
    const [resetPassword] = useResetPasswordMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    if (!email) {
        navigate('/')
    }

    const handlePasswordsChange = (e) => {
        setPasswords({ ...passwords, [e.target.name]: e.target.value })
    }

    const validatePasswords = () => {
        if (passwords.new_password.length < 8) {
            alert('Password must include at least 8 characters')
            return;
        }
        if (passwords.new_password !== passwords.confirm_password) {
            alert("Password don't match")
            return false;
        }

        return true;
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validatePasswords()) {
            const response = await resetPassword({ email, password: passwords.new_password }).unwrap()
            if (response) {
                console.log({ response })
                navigate('/success')
            }
        }
    }

    return (
        <AuthWrapper>
            <LogoHeader />
            <div className="text-center">
                <h1 className="text-[30px] font-semibold">Ndrysho fjalëkalimin</h1>
                <h3 className="text-gray-600">Fut një fjalëkalim tjetër nga i mëparshmi</h3>
            </div>
            <div className="my-4 mt-6">
                <form onSubmit={handleFormSubmit}>
                    <div className="flex flex-col gap-6">
                        <InputField type="password" name="new_password" label="Fjalëkalimi i ri" value={passwords.new_password} placeholder="••••••••" onChange={handlePasswordsChange} required={true} />
                        <InputField type="password" name="confirm_password" label="Konfirmo fjalëkalimin e ri" value={passwords.confirm_password} placeholder="••••••••" onChange={handlePasswordsChange} required={true} />
                        <SubmitButton value="Rivendos fjalëkalimin" />
                    </div>
                </form>
            </div>
        </AuthWrapper>
    )
}