import { useEffect, useState } from "react";
import ButtonNew from "../components/Button/ButtonNew";
import { useCreateReceiptMutation } from "../store/services/receiptServices";
import InputField from "../components/auth/InputField";
import imageCompression from "browser-image-compression";

const processImageWithGoogleOCR = async (imageData) => {
  const apiKey = "AIzaSyBEa1wa446LMnhRfxik9ZaczsLYdHrXstQ";
  const apiUrl = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;
  const body = JSON.stringify({
    requests: [
      {
        image: {
          content: imageData.split(",")[1],
        },
        features: [
          {
            type: "DOCUMENT_TEXT_DETECTION",
            maxResults: 1,
          },
        ],
      },
    ],
  });
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const result = await response.json();
    return result.responses[0].fullTextAnnotation.text;
  } catch (error) {
    console.error("OCR Error with Google Vision API:", error);
    return null;
  }
};

const AddReceipt = ({ onClose }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);
  const [fields, setFields] = useState({
    fiscalNumber: "",
    vatAmount: "",
    totalWithoutVat: "",
    total: "",
    businessName: "",
    businessAddress: "",
  });

  const [createReceipt, { isLoading }] = useCreateReceiptMutation();

  const normalizeDecimalValue = (value) => {
    if (!value) return "";
    return value.replace(",", ".").trim();
  };

  const parseFields = (text) => {
    const lines = text ? text.split("\n") : [];
    console.log("🚀 ~ parseFields ~ lines:", lines);

    const parsedFields = {
      fiscalNumber: "",
      vatAmount: "",
      totalWithoutVat: "",
      total: "",
      businessName: "",
      businessAddress: "",
    };

    const businessAcronyms = [
      "Rr\\.",
      "NR\\.",
      "N\\.",
      "Prishtinë",
      "PRISHTINE",
      "Pristina",
      "Prizren",
      "Pejë",
      "Gjakovë",
      "Mitrovicë",
      "Ferizaj",
      "Gjilan",
      "Podujevë",
      "Obiliq",
      "Lipjan",
      "Fushë Kosovë",
      "Suharekë",
      "Drenas",
      "Istog",
      "Deçan",
      "Malishevë",
      "Kamenicë",
      "Dragash",
      "Rahovec",
      "Viti",
      "Shtime",
      "Klinë",
      "Skenderaj",
      "Vushtrri",
      "Hani i Elezit",
      "Kaçanik",
      "Novobërdë",
      "Leposaviq",
      "Zubin Potok",
      "Zveçan",
      "Graçanicë",
      "Ranillug",
      "Partesh",
      "Kllokot",
      "Junik",
      "Mamushë",
      "Shtërpcë",
    ].join("|");

    lines?.forEach((line, index) => {
      if (
        line.match(
          /(NUI\/NUMRI FISKAL|NUMRI FISKAL|NR. FISKAL|NR.FISKAL|Numri Fiskal)/i
        )
      ) {
        const sameLineMatch = line.match(
          /(NUI\/NUMRI FISKAL|NUMRI FISKAL|NR\. FISKAL|Numri Fiskal)[:\s]*(\d+)/i
        );
        if (sameLineMatch) {
          parsedFields.fiscalNumber = sameLineMatch[2];
        } else {
          for (let i = index + 1; i < lines.length; i++) {
            const nextLine = lines[i]?.trim();

            const nextLineMatch = nextLine.match(/^(\d{9,})$/);
            if (nextLineMatch) {
              parsedFields.fiscalNumber = nextLineMatch[1];
              break;
            }
          }
        }
      } else if (
        line.match(/TVSH (E=|C=|D=)/i) ||
        line.match(/TVSH (E-|C-|D-)/i) ||
        line.match(/TUSH (E=|C=|D=)/i) ||
        line.match(/TUSH (E-|C-|D-)/i)
      ) {
        let vatAmountFound = false;
        const sameLineMatch =
          line.match(/TVSH.*?(\d+[\.,]\d{2})$/) ||
          line.match(/TUSH.*?(\d+[\.,]\d{2})$/);
        if (sameLineMatch) {
          parsedFields.vatAmount = normalizeDecimalValue(sameLineMatch[1]);
          vatAmountFound = true;
        } else {
          const searchRange = 10;
          for (let i = 1; i <= searchRange; i++) {
            const lineToCheck = lines[index + i]?.trim();
            if (!lineToCheck) break;
            const vatMatch = lineToCheck.match(/^(\d+[\.,]\d{2})$/);
            if (vatMatch) {
              const vatCandidate = normalizeDecimalValue(vatMatch[1]);
              const isDuplicate = vatCandidate === parsedFields.total;
              if (!isDuplicate) {
                parsedFields.vatAmount = vatCandidate;
                vatAmountFound = true;
                break;
              }
            }
            if (
              lineToCheck.match(
                /TOTALI|PARA TE GATSHME|ARTIKUJ|ORA|DATA|FISKAL/i
              )
            ) {
              break;
            }
          }
        }
      } else if (line.match(/TOT\. PA/i)) {
        let totalWithoutVatFound = false;
        const sameLineMatch = line.match(/TOT\. PA TVSH.*?(\d+[\.,]\d{2})$/i);
        if (sameLineMatch) {
          parsedFields.totalWithoutVat = normalizeDecimalValue(
            sameLineMatch[1]
          );
          totalWithoutVatFound = true;
        } else {
          const searchRange = 10;
          for (let i = 1; i <= searchRange; i++) {
            const lineToCheck = lines[index + i]?.trim();
            if (!lineToCheck) break;
            const totalMatch = lineToCheck.match(/^(\d+[\.,]\d{2})$/);
            if (totalMatch) {
              const totalCandidate = normalizeDecimalValue(totalMatch[1]);

              if (!totalWithoutVatFound) {
                parsedFields.totalWithoutVat = totalCandidate;
                totalWithoutVatFound = true;
                break;
              }
            }
            if (
              lineToCheck.match(
                /TOTALI|PARA TE GATSHME|TVSH|ARTIKUJ|ORA|DATA|FISKAL/i
              )
            ) {
              break;
            }
          }
        }
      } else if (line.includes("TOTALI NE EURO")) {
        const sameLineMatch = line.match(/TOTALI NE EURO.*?(\d+[\.,]\d{2})/);
        if (sameLineMatch) {
          parsedFields.total = normalizeDecimalValue(sameLineMatch[1]);
        } else {
          for (let i = index + 1; i < lines.length; i++) {
            const nearbyLine = lines[i]?.trim();
            const nearbyMatch = nearbyLine.match(/^(\d+[\.,]\d{2})$/);
            if (nearbyMatch) {
              parsedFields.total = normalizeDecimalValue(nearbyMatch[1]);
              break;
            }
          }
        }
      } else if (!parsedFields.businessName && line.match(/"([^"]*)"/g)) {
        parsedFields.businessName = line.trim();
      } else if (
        !parsedFields.businessAddress &&
        line.match(new RegExp(businessAcronyms, "i"))
      ) {
        parsedFields.businessAddress = line.trim();
      }
    });

    console.log("Parsed Fields:", parsedFields);

    return parsedFields;
  };

  useEffect(() => {
    if (uploadedImage) {
      const objectUrl = URL.createObjectURL(uploadedImage);
      setImagePreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [uploadedImage]);

  // const handleFileUpload = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setUploadedImage(file);
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const imageData = e.target.result;
  //       const extractedText = await processImageWithGoogleOCR(imageData);
  //       const parsedFields = parseFields(extractedText);
  //       setFields(parsedFields);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800;
          const maxHeight = 800;
          let width = img.width;
          let height = img.height;

          if (width > height && width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          } else if (height > width && height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);


          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg", 
            0.8 
          );
        };
        img.onerror = (err) => reject(err);
        img.src = e.target.result;
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedBlob = await compressImage(file);
        const compressedFile = new File([compressedBlob], file.name, {
          type: "image/jpeg",
        });

        setUploadedImage(compressedFile);
        const reader = new FileReader();
        reader.onload = async (e) => {
          const imageData = e.target.result;
          const extractedText = await processImageWithGoogleOCR(imageData);
          const parsedFields = parseFields(extractedText);
          setFields(parsedFields);
        };
        reader.readAsDataURL(compressedFile);

        const objectUrl = URL.createObjectURL(compressedFile);
        setImagePreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  };

  const handleFieldChange = (field, value) => {
    setFields((prevFields) => ({ ...prevFields, [field]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("vat_number", fields.fiscalNumber);
      formData.append("vat_amount", fields.vatAmount);
      formData.append("total_without_vat", fields.totalWithoutVat);
      formData.append("total_amount", fields.total);
      formData.append("business_name", fields.businessName);
      formData.append("address", fields.businessAddress);
      if (uploadedImage) {
        formData.append("image", uploadedImage);
      }

      const response = await createReceipt(formData).unwrap();
      console.log("API Response:", response);

      console.log("Receipt successfully created!");
      onClose();
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg sm:p-6 p-4 sm:w-[90%] w-[80%] max-w-4xl max-h-[800px] overflow-y-auto">
        <div className="flex justify-between items-center pb-2 mb-4">
          <h2 className="text-lg font-bold">
            {uploadedImage ? "Detajet e fatures" : "Ngarko nje fature"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800"
          >
            ✕
          </button>
        </div>
        {uploadedImage ? (
          <div className="flex sm:flex-row flex-col gap-8">
            <div className="sm:w-1/3 w-full">
              <p className="text-gray-500 text-sm mb-2">Dokumenti:</p>
              <div className="border rounded p-2">
                <img
                  src={imagePreview}
                  alt="Uploaded"
                  className="w-full h-full object-contain"
                />
              </div>
              <button
                className="mt-4 bg-gray-200 text-sm py-2 px-4 rounded hover:bg-gray-300"
                onClick={() => setUploadedImage(null)}
              >
                Shto nje tjeter
              </button>
            </div>
            <div className="sm:w-2/3 w-full">
              <form className="flex flex-col gap-4">
                <InputField
                  label="Emri i Biznesit"
                  id="businessName"
                  value={fields.businessName}
                  onChange={(value) => handleFieldChange("businessName", value)}
                />
                <InputField
                  label="Adresa e Biznesit"
                  id="businessAddress"
                  value={fields.businessAddress}
                  onChange={(value) =>
                    handleFieldChange("businessAddress", value)
                  }
                />
                <InputField
                  label="Numri fiskal"
                  id="fiscalNumber"
                  value={fields.fiscalNumber}
                  onChange={(value) => handleFieldChange("fiscalNumber", value)}
                />
                <InputField
                  label="Totali"
                  id="total"
                  value={fields.total}
                  onChange={(value) => handleFieldChange("total", value)}
                />
                <InputField
                  label="TVSH"
                  id="vatAmount"
                  value={fields.vatAmount}
                  onChange={(value) => {
                    handleFieldChange("vatAmount", value);
                  }}
                />
                <InputField
                  label="Totali pa TVSH"
                  id="totalWithoutVat"
                  value={fields.totalWithoutVat}
                  onChange={(value) =>
                    handleFieldChange("totalWithoutVat", value)
                  }
                />
                <div className="flex gap-3">
                  <ButtonNew
                    text="Konfirmo"
                    color="bg-primary"
                    textStyles="text-white"
                    width="w-1/3"
                    height="h-[44px]"
                    onClick={handleSave}
                    isLoading={isLoading}
                  />
                  <ButtonNew
                    text="Anulo"
                    color="bg-lightGrey"
                    textStyles="text-black"
                    width="w-1/3"
                    height="h-[44px]"
                    onClick={onClose}
                  />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="flex gap-8 items-center">
            <div className="flex-1">
              <div className="flex flex-col items-center gap-4 p-5 text-center border-dashed border rounded">
                <div className="bg-lighterGrey rounded-full flex items-center justify-center w-14 h-14">
                  <img src="/upload.png" alt="Upload" className="w-8 h-8" />
                </div>
                <p className="text-primary font-semibold text-sm">
                  Kliko per te krijuar nje fature
                </p>
                <p className="text-greyColor text-xs">
                  SVG, PNG, JPG, ose GIF (max. 800x400px)
                </p>
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="file-upload"
                  onChange={handleFileUpload}
                />
                <label
                  htmlFor="file-upload"
                  className="bg-primary text-white text-sm py-2 px-4 rounded cursor-pointer mt-4"
                >
                  Ngarko nje fature
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddReceipt;
