export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const existingRegistration =
        await navigator.serviceWorker.getRegistration();

      if (existingRegistration) {
        return false;
      } else {
        const registration = await navigator.serviceWorker.register("/sw.js");
        console.log("Service Worker registered successfully:", registration);
        return true;
      }
    } catch (error) {
      console.error("Service Worker registration or check failed:", error);
    }
  } else {
    console.warn("Service Workers are not supported in this browser.");
  }
};
