export default function InputField({ type, name, label, value, placeholder, onChange, isDisabled, required }) {
    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-sm text-[#344054] font-medium">{label}</label>
            <input
                type={type}
                name={name}
                id={name}
                className="w-full border border-gray-300 shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                placeholder={placeholder}
                required={required}
                value={value}
                onChange={onChange}
                disabled={isDisabled}
            />
        </div>
    )
}