import { useState } from "react";
import InputField from "../components/auth/InputField";
import LogoHeader from "../components/auth/LogoHeader";
import SubmitButton from "../components/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import AuthWrapper from "../components/auth/AuthWrapper";
import { useForgotPasswordMutation } from "../store/services/authServices";
import { useDispatch } from "react-redux";
import { forgetPasswordActions } from "../store/forgetPasswordSlice";

export default function ForgotPassword() {

    const [email, setEmail] = useState('')
    const [forgotPassword] = useForgotPasswordMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [error, setError] = useState(false)

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await forgotPassword({ email }).unwrap()
            if (response) {
                console.log({ response })
                dispatch(forgetPasswordActions.addEmail(email))
                navigate('/verify')
            }
        }
        catch (error) {
            setError(true)
            console.log("Couldn't access forgot password")
        }
    }

    return (
        <AuthWrapper>
            <LogoHeader />
            <div className="text-center">
                <h1 className="text-[30px] font-semibold">Riktheni fjalëkalimin</h1>
                <h3 className="text-gray-600">Shkruani emailin tuaj</h3>
            </div>
            <div className="my-4 mt-6">
                <form onSubmit={handleFormSubmit}>
                    <div className="flex flex-col gap-6">
                        <InputField type="text" name="email" label="Email" value={email} placeholder="Shkruani emailin tuaj" onChange={handleEmailChange} required={true} />
                        { error && <div className="text-red-600 font-bold">Nuk ekziston llogari me këtë email</div> }
                        <SubmitButton value="Dërgo" />
                    </div>
                </form>
            </div>
            <div className="text-center text-sm text-gray-600 mt-8">
                A e mban mend fjalëkalimin? <Link to="/login"><span className="font-semibold text-primary hover:underline">Kyqu</span></Link>
            </div>
        </AuthWrapper>
    )
}