import React, { useState } from "react";
import Search from "../Search/Search";
import ReceiptTable from "./ReceiptTable";
import Pagination from "../Pagination/Pagination";
import SideBar from "../SideBar/SideBar";
import { useArchiveReceiptsQuery } from "../../store/services/receiptServices";
import ReceiptBorder from "./ReceiptBorder";
import NavBar from "../NavBar/NavBar";

const ArchivedReceipts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [receiptsPerPage] = useState(10);

  const { data: receiptsData } = useArchiveReceiptsQuery();

  const receipts = Array.isArray(receiptsData) ? receiptsData : [];

  const totalPages = Math.ceil(receipts.length / receiptsPerPage);

  const indexOfLastReceipt = currentPage * receiptsPerPage;
  const indexOfFirstReceipt = indexOfLastReceipt - receiptsPerPage;
  const currentReceipts = receipts.slice(
    indexOfFirstReceipt,
    indexOfLastReceipt
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <SideBar />
      <div className="p-4 sm:ml-64">
        <div className="sm:hidden block sm:pb-0 pb-5">
          <NavBar />
        </div>
        <>
          {receipts.length === 0 ? (
            <div className="flex flex-col items-center justify-center min-h-screen">
              <img
                src="/emptyReceipe.png"
                alt="No Data"
                className="w-32 h-32 sm:w-48 sm:h-48"
              />
              <p className="text-lg text-center mt-4">
                Nuk keni fatura të arkivuara të ruajtura për ti shfaqur.
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center">
                <Search />
                <ReceiptTable
                  data={currentReceipts}
                  title="Faturat e Arkivuara"
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
                <ReceiptBorder data={currentReceipts} />
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default ArchivedReceipts;
