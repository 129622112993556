import _ from 'lodash';

export default function findChangedFields(data, editedData) {
    const changedFields = {};

    Object.keys(data).forEach(key => {
        if (!_.isEqual(data[key], editedData[key])) {
            changedFields[key] = { old: data[key], new: editedData[key] };
        }
    });

    return changedFields;
}