import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/authSlice";
import { useGetOwnAccountQuery } from "../../store/services/profileServices";
import getInitials from "../../utils/getInitials";

const SideBar = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userData, isLoading } = useGetOwnAccountQuery();

  const handleLogout = () => {
    dispatch(authActions.removeToken());
    localStorage.removeItem("giz_access_token");
    localStorage.removeItem("giz_refresh_token");
    navigate("/login");
  };

  return (
    <div>
      <aside
        className={`fixed top-0 left-0 z-40 w-72 h-screen bg-white transition-transform transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0 sm:block`}
        aria-label="Sidebar"
      >
        <div className="p-4 h-full">
          <div className="h-full px-3 py-4 border flex flex-col justify-between rounded-xl overflow-y-auto bg-white">
            <ul className="space-y-2 font-medium">
              <li>
                <a
                  href="/"
                  className="flex items-center p-2 text-gray-900 rounded-lg  group"
                >
                  <img
                    src="/full_logo.svg"
                    alt="Icon"
                    className="text-gray-500 transition duration-75  "
                  />
                </a>
              </li>
              <li>
                <Link
                  to="/"
                  className={`flex items-center p-2 rounded-lg group ${
                    location.pathname === "/"
                      ? "bg-gray-100 text-gray-900 "
                      : "text-gray-900   "
                  }`}
                >
                  <img
                    src="/ballina.png"
                    alt="Ballina"
                    className="text-gray-500 transition duration-75   "
                  />
                  <span className="flex-1 ms-3 whitespace-nowrap">Ballina</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/archived-receipts"
                  className={`flex items-center p-2 rounded-lg group ${
                    location.pathname === "/archived-receipts"
                      ? "bg-gray-100 text-gray-900  "
                      : "text-gray-900   "
                  }`}
                >
                  <img
                    src="/arkiva.png"
                    alt="Arkiva"
                    className="text-gray-500 transition duration-75   "
                  />
                  <span className="flex-1 ms-3 whitespace-nowrap">Arkiva</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/profile"
                  className={`flex items-center p-2 rounded-lg group ${
                    location.pathname === "/profile"
                      ? "bg-gray-100 text-gray-900  "
                      : "text-gray-900   "
                  }`}
                >
                  <img
                    src="/profili.png"
                    alt="Profili"
                    className="text-gray-500 transition duration-75   "
                  />
                  <span className="flex-1 ms-3 whitespace-nowrap">Profili</span>
                </Link>
              </li>
            </ul>
            <div className="flex">
              <div className="flex gap-3">
                <div className="bg-primary rounded-full w-10 h-10 flex items-center justify-center">
                  <p className="text-white">
                    {userData?.name ? getInitials(userData.name) : ""}
                  </p>
                </div>
                <div>
                  <p className="text-sm">
                    {isLoading ? "Loading..." : userData?.name || "Unknown"}
                  </p>
                  <p className="text-xs text-greyColor">
                    {isLoading ? "Loading..." : userData?.email || "No email"}
                  </p>
                </div>
                <div
                  className="flex items-center justify-center cursor-pointer"
                  onClick={handleLogout}
                >
                  <img src="/logout.png" alt="Logout" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default SideBar;
