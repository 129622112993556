import { Navigate } from "react-router-dom";
import { registerServiceWorker } from "../../registerServiceWorker";
import { subscribeUserToPush } from "../../subscribeUserToPush";
import { useSubscribeMutation } from "../../store/services/notificationServices";
import { useEffect } from "react";

export const ProtectedRoute = ({ token, loading, children }) => {
  const [subscribe] = useSubscribeMutation();

  useEffect(() => {
    if (token) {
      const handleSubscription = async () => {
        try {
          // Request notification permission
          const permission = await Notification.requestPermission();

          if (permission === "granted") {
            // Continue with the subscription process
            const registration = await registerServiceWorker();
            const subscription = await subscribeUserToPush();
            if (typeof subscription === "undefined") {
              return;
            }
            const response = await subscribe(
              JSON.parse(JSON.stringify(subscription))
            ).unwrap();
          } else {
            console.log("after else");
            console.log("Notification permission denied");
          }
        } catch (error) {
          console.error("Error during subscription process:", error);
        }
      };

      handleSubscription();
    }
  }, [subscribe, token]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!token) {
    console.log("navigating to login because no token");
    return <Navigate to="/login" replace />;
  }
  return children;
};
